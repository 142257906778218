import './multiselect-lib';
import $ from 'jquery';
import { debounce } from 'lodash-es';
import { fetchWithCredentials } from '@universityofwarwick/serverpipe';

export default function SearchForm(formSelector) {
  const $form = $(formSelector);
  const $resetFilterButton = $form.find('.reset-filter-button');
  let lastChangedOption;

  const initResetButton = () => {
    if ($form.serializeArray()
      .filter(i => Boolean(i.value))
      .filter(i => i.name !== 'keywords').length !== 0) {
      $resetFilterButton.toggleClass('disabled', false)
        .prop('disabled', false);
    } else {
      $resetFilterButton.addClass('disabled', true)
        .prop('disabled', true);
    }
  };

  const onFilterChanged = () => {
    $form.trigger('filter-changed');
  };

  const initMultiSelect = () => {
    $form.find('select.multiselect.department')
      .multiselect({
        buttonWidth: '100%', // i really do not like this
        enableClickableOptGroups: true,
        nonSelectedText: 'Any department',
        nSelectedText: 'departments selected',
        allSelectedText: 'All departments',
        onChange: onFilterChanged,
      });
    $form.find('select.multiselect.level')
      .multiselect({
        buttonWidth: '100%',
        nonSelectedText: 'Any level',
        nSelectedText: 'levels selected',
        allSelectedText: 'All levels',
        onChange: onFilterChanged,
      });
    $form.find('select.multiselect.creditValue')
      .multiselect({
        buttonWidth: '100%',
        nonSelectedText: 'Any credit value',
        nSelectedText: 'credit values selected',
        allSelectedText: 'All credit values',
        onChange: onFilterChanged,
      });
    $form.find('select.multiselect.assessmentType')
      .multiselect({
        buttonWidth: '100%',
        dropRight: true,
        enableClickableOptGroups: true,
        nonSelectedText: 'Any assessment type',
        nSelectedText: 'assessment types selected',
        allSelectedText: 'All assessment types',
        onChange: onFilterChanged,
      });
    $form.find('select.multiselect.academicYear')
      .multiselect({
        buttonWidth: '100%',
        nonSelectedText: 'Any academic year',
        nSelectedText: 'academic years selected',
        allSelectedText: 'All academic years',
        onChange: onFilterChanged,
      });
  };

  const rebuildMultiSelect = () => {
    $form.find('select.multiselect.department')
      .multiselect('rebuild');
    $form.find('select.multiselect.level')
      .multiselect('rebuild');
    $form.find('select.multiselect.creditValue')
      .multiselect('rebuild');
    $form.find('select.multiselect.assessmentType')
      .multiselect('rebuild');
    $form.find('select.multiselect.academicYear')
      .multiselect('rebuild');
  };

  const fetchResult = async ({
    fetchFrom,
    page,
  } = {}) => {
    const searchParams = $form.serialize();
    const url = fetchFrom ? new URL(fetchFrom) : new URL(`${window.location.pathname}?${searchParams}`, window.location.origin);
    url.searchParams.set('page', page || url.searchParams.get('page') || 0);

    const response = await fetchWithCredentials(url.href)
      .then((r) => {
        if (r.redirected && r.url) {
          window.location = r.url;
          return null;
        }
        return r.text();
      });

    const $response = $(response);

    if (window.location.href !== url.href) {
      window.history.pushState(
        { href: url.href },
        document.title,
        url.href,
      );
    }

    [
      '.search-results',
      'select.multiselect.department',
      'select.multiselect.level',
      'select.multiselect.creditValue',
      'select.multiselect.assessmentType',
      'select.multiselect.academicYear',
    ].forEach((selector) => {
      $form.find(selector)
        .html($response.find(selector)
          .html());
    });

    $form.trigger('result-refreshed');
  };

  const initPagination = () => {
    $form.find('.pagination-button')
      .on('click', async (e) => {
        e.preventDefault();
        await fetchResult({
          page: $(e.target)
            .val(),
        });
      });
  };

  $form.on('submit', async (e) => {
    e.preventDefault();
    await fetchResult();
  });

  const refocus = () => {
    if (!lastChangedOption) return;
    $('button.multiselect[aria-expanded=true]')
      .siblings('ul.dropdown-menu')
      .find(`input[value='${lastChangedOption}']`)
      .closest('a')
      .focus();
  };

  $form.on('filter-changed', debounce(async () => {
    await fetchResult({ page: 0 });
    refocus();
  }, 100));

  $form.on('result-refreshed', () => {
    initResetButton();
    initPagination();
    rebuildMultiSelect();
  });

  $form.on('page-loaded', () => {
    initResetButton();
    initPagination();
    initMultiSelect();
    $('.filter-button-place-holder')
      .remove();
  });

  $form.find('input[name=route]')
    .on('change', onFilterChanged);

  $resetFilterButton.on('click', async (e) => {
    e.preventDefault();
    $('select.multiselect')
      .toArray()
      .map($)
      .forEach(($ms) => {
        $ms.multiselect('deselectAll', false);
        $ms.multiselect('refresh');
      });
    await fetchResult({ page: 0 });
  });

  $(window)
    .on('popstate', async ({ originalEvent }) => {
      if (originalEvent.state && originalEvent.state.href) {
        await fetchResult({ fetchFrom: originalEvent.state.href });
      }
    });


  $(window)
    .on('keyup', ({ target }) => {
      const $target = $(target);
      if ($target.parents('.multiselect-container').length < 0) {
        lastChangedOption = null;
        return;
      }
      lastChangedOption = $target.find('input')
        .prop('value');
    });
  $form.trigger('page-loaded');
}
